









































import Vue from "vue";
import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import moment, { Moment } from "moment";
import { mapMutations, mapState } from "vuex";
import MNotificationVue from "@/mixins/MNotification.vue";
import { contactServices } from "@/services-v2/contact.service";
import { settingsServices } from "@/services/settings.service";
import { ResponseDetailInvoiceAR } from "@/models/interface-v2/invoice.interface";
import { Mode } from "@/models/enums/global.enum";
export default Vue.extend({
  name: "InvoiceTaxDetails",
  components: {
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      form: {
        taxType: "",
        taxRegistrationNumber: "",
        taxRegistrationName: "",
        taxInvoiceUploaded: false,
        taxInvoiceDate: moment() as Moment | null,
        taxInvoiceNumber: "",
      },
      rules: {},
    };
  },
  computed: {
    ...mapState({
      storeForm: (st: any) => st.invoiceStore.form,
      tabTaxDetailSource: (st: any) => st.invoiceStore.tabTaxDetailSource,
      detailInvoice: (st: any) => st.invoiceStore.detailInvoice,
    }),
    isDetail(): boolean {
      return !!this.detailInvoice.status;
    }
  },
  watch: {
    "storeForm.customerId"(newValue): void {
      if (newValue) {
        this.getCustomerDetail(newValue);
      }
    },
  },
  created(): void {
    if (this.$route.query.status && this.$route.meta.mode === Mode.VIEW) {
      this.fillForm();
    } else {
      this.getCustomerDetail(this.storeForm.customerId);
    }
  },
  methods: {
    moment,
    ...mapMutations({
      setTabTaxDetailSource: "invoiceStore/SET_TAB_TAX_DETAIL_SOURCE",
    }),
    commitStore(): void {
      this.setTabTaxDetailSource(this.form);
    },
    onChangeInvoiceUploaded(): void {
      this.commitStore();
    },
    onChangeInvoiceDate(): void {
      this.commitStore();
    },
    async getCustomerDetail(customerId: string): Promise<void> {
      try {
        if (!customerId) return;
        const detail = await contactServices.getContactData(customerId);
        this.form.taxRegistrationNumber = detail.taxRegisNumber;
        this.form.taxRegistrationName = detail.taxRegisName;
        this.getTaxDetail(detail.customerData.taxId || "");
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    /**
     * get detail tax invoice code
     */
    async getTaxDetail(taxId: string): Promise<void> {
      try {
        const search = `secureId~${taxId}`;
        const { data } = await settingsServices.listOfTaxInvoiceCode({ search });
        this.form.taxType = data.length ? data[0].code : "";
        this.commitStore();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    fillForm(): void {
      const detail = this.detailInvoice as ResponseDetailInvoiceAR;
      this.form = {
        taxType: detail.taxType,
        taxRegistrationNumber: detail.taxRegistrationNumber,
        taxRegistrationName: detail.taxRegistrationName,
        taxInvoiceUploaded: detail.taxIsUploaded,
        taxInvoiceDate: this.moment(detail.taxInvoiceDate),
        taxInvoiceNumber: detail.taxInvoiceNumber,
      };
    },
  }
});
