var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { staticClass: "p-3" },
    [
      _c(
        "a-form-model",
        {
          ref: "formModalTaxDetails",
          attrs: {
            model: _vm.tabTaxDetailSource,
            rules: _vm.rules,
            "wrapper-col": { span: 18 },
            "label-col": { span: 6 }
          }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_customer_tax_type") } },
            [
              _c("span", { staticClass: "ant-form-text" }, [
                _vm._v(_vm._s(_vm.tabTaxDetailSource.taxType || "-"))
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_tax_registration_number") } },
            [
              _c("span", { staticClass: "ant-form-text" }, [
                _vm._v(
                  _vm._s(_vm.tabTaxDetailSource.taxRegistrationNumber || "-")
                )
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_tax_registration_name") } },
            [
              _c("span", { staticClass: "ant-form-text" }, [
                _vm._v(
                  _vm._s(_vm.tabTaxDetailSource.taxRegistrationName || "-")
                )
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_tax_invoice_uploaded") } },
            [
              _c("a-checkbox", {
                attrs: { disabled: _vm.isDetail },
                on: { change: _vm.onChangeInvoiceUploaded },
                model: {
                  value: _vm.form.taxInvoiceUploaded,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "taxInvoiceUploaded", $$v)
                  },
                  expression: "form.taxInvoiceUploaded"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_tax_invoice_date") } },
            [
              _vm.isDetail
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("date")(_vm.tabTaxDetailSource.taxInvoiceDate)
                      )
                    )
                  ])
                : _c("a-date-picker", {
                    attrs: {
                      placeholder: _vm.$t("lbl_choose"),
                      format: _vm.DEFAULT_DATE_FORMAT
                    },
                    on: { change: _vm.onChangeInvoiceDate },
                    model: {
                      value: _vm.form.taxInvoiceDate,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "taxInvoiceDate", $$v)
                      },
                      expression: "form.taxInvoiceDate"
                    }
                  })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_tax_invoice_number") } },
            [
              _c("span", { staticClass: "ant-form-text" }, [
                _vm._v(_vm._s(_vm.tabTaxDetailSource.taxInvoiceNumber || "-"))
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }